<template>
  <div v-if="loading" class="loading"><loader></loader></div>
  <div v-else class="choose-table">
    <div v-if="brainstormTitle" class="brainstorm-title">
      {{ brainstormTitle }}
    </div>
    <div class="title">{{ $t("Brainstorm.chooseTheDesk") }}</div>
    <div class="tables">
      <div v-for="table in tables" :key="table.id" class="table_wrapper">
        <div
          class="table"
          :style="{ backgroundColor: getTableColor(table.name) }"
          @click="handleTableClick(table.id)"
        >
          <span>{{ table.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import getTableColorByNumber from "@/services/TableColorsService";
import Loader from "@/components/common/elements/Loader";
import { auth, brainstorm, room } from "@/store/modules/store.namespaces";
import { UPDATE_BY_TOKEN } from "@/store/modules/auth/action-types";
import {
  GET_ROUNDED_TABLE_IDEAS,
  GET_ROUNDED_TABLES,
  SET_TABLE_FOR_USER,
} from "@/store/modules/brainstorm/action-types";

export default {
  name: "TablesLanding",
  components: { Loader },
  props: ["brainstormTitle"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(brainstorm, {
      tables: "roundedTables",
    }),
    ...mapState(room, {
      room: "roomNumber",
    }),
  },
  created() {
    if (this.tables < 1 && this.room) {
      this.getRoundedTables();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(auth, {
      updateUserByToken: UPDATE_BY_TOKEN,
    }),
    ...mapActions(brainstorm, {
      getRoundedTables: GET_ROUNDED_TABLES,
      getRoundedTablesIdeas: GET_ROUNDED_TABLE_IDEAS,
      setTableForUser: SET_TABLE_FOR_USER,
    }),
    statusIdea() {
      this.$emit("statusIdea", "active");
    },
    getTableColor(tableName) {
      return getTableColorByNumber(tableName);
    },
    handleTableClick(id) {
      this.getRoundedTablesIdeas(true);
      this.setTableForUser(id).then(() => {
        this.updateUserByToken().then(() => {
          this.statusIdea();
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.loading {
  margin: 25px auto;
}
.choose-table {
  padding-top: 5px;
  text-align: center;
  .brainstorm-title {
    font-size: 32px;
    margin: 15px 0;
  }
  .title {
    font-size: 24px;
  }
  .tables {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 660px;
    margin: 10px auto 0;

    .table_wrapper {
      padding: 12px;
      .table {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: aliceblue;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        box-shadow: 0 0 0 rgba(50, 50, 50, 0.5);
        span {
          display: inline-block;
          font-size: 64px;
        }
        &:hover {
          transform: scale(1.03);
          box-shadow: 2px 2px 5px rgba(50, 50, 50, 0.5);
        }
        &.t1 {
          background-color: #f28b00;
        }
        &.t2 {
          background-color: #ff7676;
        }
        &.t3 {
          background-color: #73c815;
        }
        &.t4 {
          background-color: #5ed5ff;
        }
        &.t5 {
          background-color: #c37eff;
        }
        &.t6 {
          background-color: #ffda65;
        }
        &.t7 {
          background-color: #ff6da9;
        }
        &.t8 {
          background-color: #6294ff;
        }
        &.t9 {
          background-color: #c8c8c8;
        }
        &.t10 {
          background-color: #caff70;
        }
        &.t11 {
          background-color: #44dfd2;
        }
        &.t12 {
          background-color: #c19e80;
        }
      }
    }
  }
}
</style>
