<template>
  <div class="brainstorm">
    <div v-if="allIdea === 'idle'" class="brainstorm-is-off" :style="headerStyles">
      {{ $t("Brainstorm.brainstormIsntStarted") }}
    </div>
    <table-theme-idea
      v-if="themeShow && allIdea === 'tables'"
      @statusIdea="statusIdea"
    />
    <tables-landing
      v-else-if="allIdea === 'tables'"
      :brainstorm-title="currentBrainstormTitle"
      @statusIdea="statusIdea"
    />

    <rounded-tables
      v-if="allIdea === 'active'"
      :brainstorm-title="currentBrainstormTitle"
      @statusIdea="statusIdea"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TablesLanding from "./components/TablesLanding";
import RoundedTables from "./components/RoundedTable";
import TableThemeIdea from "./components/TableThemeIdea";
import { BRAINSTORM_TITLE } from "@/store/modules/brainstorm/getter-types";
import { auth, brainstorm, room } from "@/store/modules/store.namespaces";
import { UPDATE_BY_TOKEN } from "@/store/modules/auth/action-types";
import {
  GET_BRAINSTORMS,
  GET_ROUNDED_TABLES,
  SET_TABLE_FOR_USER,
} from "@/store/modules/brainstorm/action-types";

export default {
  name: "Brainstorm",
  components: {
    TablesLanding,
    RoundedTables,
    TableThemeIdea,
  },
  data() {
    return {
      currentMode: "idle",
      allIdea: "idle",
    };
  },
  computed: {
    ...mapGetters(brainstorm, {
      currentBrainstormTitle: BRAINSTORM_TITLE,
    }),
    ...mapState(brainstorm, ["idBrainstorm", "userTableId", "roomBrainstorms"]),
    ...mapState(brainstorm, {
      roomTables: "roundedTables",
    }),
    ...mapState(room, {
      themeShow: state => state.roomInfo.theme_show,
      currentBrainstorm: state => state.roomInfo.Brainstorm,
      themeStyleColors: state => state.styleColors,
    }),
    ...mapState(room, ["roomId"]),
    userTableInRoom() {
      if (!this.userTableId) {
        return false;
      }
      return this.roomTables.some(item => {
        return item.id === this.userTableId;
      });
    },
    headerStyles() {
      if (this.themeStyleColors.innerHeadersColor) {
        return `color: ${this.themeStyleColors.innerHeadersColor}`
      }
    },
  },
  watch: {
    roomId(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.getRoundedTables();
        this.getBrainstorms();
      }
    },
    async roomTables(val) {
      if (this.themeShow) {
        await this.setTableForUser(val[0].id);
        this.updateUserByToken();
      }
      this.modeBrainstorm();
    },
    roomBrainstorms() {
      this.modeBrainstorm();
    },
  },
  mounted() {
    if (this.roomId) {
      this.getRoundedTables();
      this.getBrainstorms();
    }
  },
  methods: {
    ...mapActions(auth, {
      updateUserByToken: UPDATE_BY_TOKEN,
    }),
    ...mapActions(brainstorm, {
      getRoundedTables: GET_ROUNDED_TABLES,
      getBrainstorms: GET_BRAINSTORMS,
      setTableForUser: SET_TABLE_FOR_USER,
    }),
    statusIdea(status) {
      this.allIdea = status;
    },
    modeBrainstorm() {
      if (this.themeShow) {
        this.allIdea =
          !this.userTableInRoom || !this.idBrainstorm ? "tables" : "active";
        return;
      }
      if (!this.themeShow && !this.currentBrainstorm) {
        this.allIdea = "idle";
        return;
      }
      if (
        this.currentBrainstorm &&
        this.currentBrainstorm.id &&
        this.currentBrainstorm.status > 0
      ) {
        if ((this.userTableInRoom && !this.themeShow) || this.idBrainstorm) {
          this.allIdea = "active";
        } else {
          this.allIdea = "tables";
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.brainstorm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow: auto;
  }

  .brainstorm-is-off {
    margin-top: 15px;
    font-size: 18px;
    text-align: center;
  }
}
</style>
